import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const More = () => {
    return (
        <>
            <Navbar />

            <div>
                <div className='displaycenter'>
                    <h1 className='pointerclass  m-t-70 mtmobile1' >Other Services we offer</h1>
                </div>

                <div class="tab-content">
                    <div id="tab-1" class="tab-pane fade show p-0 active">
                        <div class="row g-4">
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hypothyroidism</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Hypothyroidism is a medical condition characterized by an underactive thyroid gland, which is located in the front of the neck.</small>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hyperthyroidism</span>
                                           
                                        </h5>
                                        <small class="fst-italic">Hyperthyroidism is a condition characterized by an overactive thyroid gland, which leads to an excessive production of thyroid hormones.</small>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Autoimmune disease Reversal program</span>
                                            
                                        </h5>
                                        <small class="fst-italic">While autoimmune diseases cannot be completely cured or reversed, certain treatments and lifestyle changes can help manage symptoms, reduce inflammation, and improve quality of life for individuals living with these conditions.</small>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Graves Disease</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Graves' disease is an autoimmune disorder that affects the thyroid gland, resulting in overproduction of thyroid hormones.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hashimotos disease</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Hashimoto's disease, also known as Hashimoto's thyroiditis, is an autoimmune disorder that affects the thyroid gland.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Thyroid nodule</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">A thyroid nodule refers to an abnormal growth or lump that forms within the thyroid gland, which is located in the front of the neck.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Goiter</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">A goiter refers to the enlargement of the thyroid gland, resulting in a visible swelling in the front of the neck.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>IV nutritional therapy</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Intravenous (IV) nutritional therapy, also known as IV vitamin therapy or IV infusion therapy, involves delivering a combination of vitamins, minerals, amino acids, and other nutrients directly into the bloodstream via an intravenous line.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Fat loss injection</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">There are various types of injections that are sometimes marketed for fat loss or weight loss purposes.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Metabolic Medicine</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Metabolic medicine, also known as metabolic disorders or metabolic medicine, is a branch of medicine that focuses on the diagnosis, management, and treatment of metabolic disorders.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Menopause</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Menopause is a natural biological process that marks the end of a woman's reproductive years.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Andropause</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Andropause, sometimes referred to as male menopause or late-onset hypogonadism, is a term used to describe the age-related decline in testosterone levels in men.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hypogonadism</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Hypogonadism refers to a condition in which the gonads (testes in males or ovaries in females) produce inadequate amounts of sex hormones, particularly testosterone in males and estrogen in females.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Growth Hormone Replacement Therapy.</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                            Growth hormone replacement therapy (GHRT) is a medical treatment that involves the administration of synthetic growth hormone to individuals with growth hormone deficiency or certain medical conditions that may benefit from increased growth hormone levels.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Continuous Glucose Monitoring</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                            Continuous Glucose Monitoring (CGM) is a method used to monitor blood glucose levels in real time throughout the day and night. It involves the use of a small sensor that is inserted under the skin to measure glucose levels in the interstitial fluid (the fluid surrounding the body's cells).</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Ambulatory Glucose Profile Monitoring</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Ambulatory Glucose Profile (AGP) monitoring is a method used to assess and analyze a person's glucose levels over an extended period of time.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Insulin Therapy</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Insulin therapy is a treatment approach used to manage diabetes, a chronic condition characterized by high blood sugar levels.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Migraine Management and Prevention.</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Migraine management and prevention involve various strategies aimed at reducing the frequency, severity, and duration of migraine attacks.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hormone Replacement Therapies (HRT)</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                            Hormone Replacement Therapy (HRT) is a medical treatment that involves supplementing or replacing hormones in the body to alleviate symptoms caused by hormonal imbalances or deficiencies.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Osteoporosis</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Osteoporosis is a medical condition characterized by the gradual weakening of bone tissue, leading to increased bone fragility and a higher risk of fractures.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Prolactin hormone Imbalance.</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">

                                            Prolactin is a hormone produced by the pituitary gland, which is located at the base of the brain. It plays a vital role in the female reproductive system.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Cushing syndrome</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Cushing's syndrome, also known as hypercortisolism, is a rare hormonal disorder characterized by prolonged exposure to high levels of the hormone cortisol in the body. Cortisol is produced by the adrenal glands and plays a crucial role in regulating various bodily functions, including metabolism, immune response, and stress management.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Parathyroid hormone imbalance.</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                            Parathyroid hormone (PTH) plays a crucial role in regulating calcium and phosphorus levels in the body. Imbalances in PTH can occur, leading to various health issues.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hormone treatment for patients undergoing SRS (Transgender Medicine )</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                            Hormone treatment is an important aspect of gender-affirming care for individuals undergoing gender confirmation surgery (also known as sex reassignment surgery or SRS).</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Hypertension</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">Hypertension, commonly known as high blood pressure, is a chronic medical condition characterized by elevated blood pressure levels consistently exceeding the normal range.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Life Style Medicine</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                            Lifestyle medicine is a branch of medicine that focuses on using evidence-based lifestyle interventions to prevent, manage, and treat chronic diseases.</small>
                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Wellness Medicine</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">

                                            Wellness medicine, also known as integrative medicine or holistic medicine, is an approach to healthcare that emphasizes the integration of conventional medicine with complementary and alternative therapies.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Sexual Dysfunction</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                        Sexual dysfunction refers to difficulties or problems that occur during any stage of the sexual response cycle, preventing an individual or couple from experiencing satisfaction from sexual activity.</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <img class="flex-shrink-0 img-fluid rounded" />
                                    <div class="w-100 d-flex flex-column text-start ps-4">
                                        <h5 class="d-flex justify-content-between border-bottom pb-2">
                                            <span>Adult Immunization</span>
                                            {/* <span class="text-primary">$115</span> */}
                                        </h5>
                                        <small class="fst-italic">
                                        Adult immunization is an important aspect of preventive healthcare that helps protect individuals from vaccine-preventable diseases.</small>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>

                <div className="m-t-30">

                </div>

                <Footer />

            </div>



        </>
    )
}

export default More