import React, { useState, useEffect } from 'react';

const CounterUpText = ({ endValue, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = Math.ceil(endValue / duration);

    const timer = setInterval(() => {
      start += increment;
      if (start >= endValue) {
        clearInterval(timer);
        start = endValue;
      }
      setCount(start);
    }, 1);

    return () => {
      clearInterval(timer);
    };
  }, [endValue, duration]);

  return <span className='count'>{count}+</span>;
};

export default CounterUpText;