import React from 'react'
import DrTanvi from '../assets/drtanvi.svg'
import DrTanvi2 from '../assets/tanvimg2.jpeg'
import DrTanvi3 from '../assets/tanvimg3.jpeg'
import DrTanvi4 from '../assets/tanvimg4.jpeg'
import DrTanvi5 from '../assets/tanvimg5.jpeg'
import DrTanvi6 from '../assets/tanvimg6.jpeg'
import DrTanvi7 from '../assets/tanvimg7.jpeg'
import DrTanvi8 from '../assets/tanvimg8.jpeg'
import DrTanvi9 from '../assets/tanvimg9.jpeg'
import DrTanvi10 from '../assets/tanvimg10.jpeg'
import DrTanvi11 from '../assets/tanvimg11.jpeg'
import DrTanvi12 from '../assets/tanvimg12.jpeg'
import DrTanvi13 from '../assets/tanvimg13.jpeg'
import DrTanvi14 from '../assets/tanvimg14.jpeg'
import DrTanvi15 from '../assets/tanvimg15.jpeg'
import DrTanvi16 from '../assets/tanvimg16.jpeg'
import DrTanvi17 from '../assets/tanvimg17.jpeg'
import DrTanvi18 from '../assets/tanvimg18.jpeg'
import DrTanvi19 from '../assets/tanvimg19.jpeg'
import DrTanvi20 from '../assets/tanvimg20.jpeg'
import DrTanvi21 from '../assets/tanvimg21.jpeg'
import DrTanvi22 from '../assets/tanvimg22.jpeg'
import DrTanvi23 from '../assets/tanvimg23.jpeg'
import DrTanviMain from '../assets/tanvimgmain.jpeg'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Gallery = () => {
  return (
    <div>
    <Navbar/>
           <div className='displaycenter m-t-40'>
            <h1 className='pointerclass mtmobile' style={{ marginBottom: '30px' }}>Gallery</h1>
          </div>
          <div className='image-grid'>
          <img data-aos="fade-right" src={DrTanvi12} alt="" />
          <img data-aos="fade-right" src={DrTanviMain} alt="" />
     
           <img data-aos="fade-right" src={DrTanvi2} alt="" />
           <img data-aos="fade-right" src={DrTanvi3} alt="" />
           <img data-aos="fade-right" src={DrTanvi4} alt="" />
           <img data-aos="fade-right" src={DrTanvi5} alt="" />
           <img data-aos="fade-right" src={DrTanvi6} alt="" />
           <img data-aos="fade-right" src={DrTanvi7} alt="" />
           <img  data-aos="fade-right" src={DrTanvi8} alt="" />
           <img data-aos="fade-right" src={DrTanvi9} alt="" />
           <img data-aos="fade-right" src={DrTanvi10} alt="" />
           <img data-aos="fade-right" src={DrTanvi11} alt="" />
           <img data-aos="fade-right" src={DrTanvi} alt="" />
           <img data-aos="fade-right" src={DrTanvi13} alt="" />
           <img data-aos="fade-right" src={DrTanvi14} alt="" />
           <img data-aos="fade-right" src={DrTanvi15} alt="" />
           <img data-aos="fade-right" src={DrTanvi16} alt="" />
           <img data-aos="fade-right" src={DrTanvi17} alt="" />
           <img data-aos="fade-right" src={DrTanvi18} alt="" />
           <img data-aos="fade-right" src={DrTanvi19} alt="" />
           <img data-aos="fade-right" src={DrTanvi20} alt="" />
           <img data-aos="fade-right" src={DrTanvi21} alt="" />
           <img data-aos="fade-right" src={DrTanvi22} alt="" />
           <img data-aos="fade-right" src={DrTanvi23} alt="" />
          </div>

          <div className='m-t-20'>

          </div>

          <Footer/>
    </div>
  )
}

export default Gallery